import { graphql } from 'gatsby';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import { find } from 'lodash';
import * as React from 'react';
import { Seo } from '../components/Seo';
import TermsOfServiceEn from '../components/TermsOfService/terms-of-service-en';
import TermsOfServiceIt from '../components/TermsOfService/terms-of-service-it';
import Layout from '../layouts/layout';

const TermsOfServicePage = props => {
    const { language } = useI18next();

    let termsComponent = language === 'it' ? <TermsOfServiceIt /> : <TermsOfServiceEn />;

    return <Layout location={props.location}>
        <div className="container p-0" style={{ margin: '60px auto' }}>
            <h2 style={{ marginBottom: '20px' }}><Trans>Terms of service</Trans></h2>
            {termsComponent}
        </div>
    </Layout>;
};

export default TermsOfServicePage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
        filter: {ns: {in: ["common", "terms-of-service-page"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
    const edge = find(data.locales.edges, [ 'node.ns', 'terms-of-service-page' ]);
    let obj = undefined;
    if (!!edge) {
        obj = JSON.parse(edge.node.data);
    }
    const title = !!obj ? obj.title : 'Terms of service';

    return <Seo title={title} description={!!obj ? obj.description : null} />;
};

