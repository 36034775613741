import React from 'react';

const TermsOfServiceEn = () => {
    return <>
        <p className="c11"><span className="c16 c3 c20"></span></p>
        <p className="c13"><span className="c5 c3">Thank</span><span>&nbsp;you </span><span className="c5 c3">for using AIDA -
            Artificial Intelligence for Document Automation. Our mission is to create a</span><span>&nbsp;more immediate
        </span><span className="c5 c3">way of working with </span><span>D</span><span className="c5 c3">ocuments </span><span>by
            providing</span><span className="c5 c3">&nbsp;an intuitive and unified platform, which ensures that your
        </span><span>D</span><span className="c5 c3">ocuments and </span><span>C</span><span className="c5 c3">ontents can be
            recognized and processed automatically in a secure and accessible way, helping you and those who work with
            you, to engage in more complex and intellectually rewarding activities. These terms of service
            (&quot;</span><span className="c5 c3 c10">Terms</span><span className="c5 c3">&quot;) relate to the use and access
            to our services, our client software and our websites (&quot;</span><span
            className="c5 c3 c10">Services</span><span className="c5 c3">&quot;).</span></p>
        <h2 className="c6" id="h.wta3ktro5jxk"><span className="c1">1. Your Files and Your Permissions</span></h2>
        <p className="c13"><span className="c5 c3">When using the AIDA Services, </span><span>the customer</span><span
            className="c5 c3">&nbsp;provides</span><span>&nbsp;f</span><span className="c5 c3">iles, content, email messages,
            contacts and more (&quot;</span><span className="c5 c3 c10">Your </span><span className="c10">F</span><span
            className="c5 c3 c10">iles</span><span className="c3 c5">&quot;). These </span><span>F</span><span
            className="c5 c3">iles </span><span>remain</span><span
            className="c5 c3">&nbsp;yours.</span><span>&nbsp;</span><span
            className="c5 c3">These Terms don</span><span>&rsquo;</span><span className="c5 c3">t </span><span>give
            us</span><span className="c5 c3">&nbsp;any rights over these </span><span>F</span><span className="c5 c3">iles
            except for the limited rights that </span><span>enable</span><span className="c4 c3">&nbsp;AIDA to offer the
            Services.</span></p>
        <p className="c11"><span className="c4 c3"></span></p>
        <p className="c13"><span>We</span><span className="c5 c3">&nbsp;need</span><span>&nbsp;your</span><span
            className="c5 c3">&nbsp;permission to </span><span>do</span><span className="c5 c3">&nbsp;operations such
        </span><span>as analyzing</span><span className="c5 c3">&nbsp;files</span><span>, managing and sharing them
        </span><span className="c5 c3">when yo</span><span>u </span><span className="c5 c3">ask us to do</span><span
            className="c5 c3">&nbsp;so. </span><span>A</span><span className="c5 c3">IDA Services also </span><span>provide
            features</span><span className="c5 c3">&nbsp;such as connecting devices via software, processing and
            pre-processing documents, optical character recognition (OCR), analyzing the document with its exclusive
            Artificial Intelligence engine, creating document thumbnails, archiving, shar</span><span>ing</span><span
            className="c5 c3">&nbsp;with other platforms, search, simple ways to </span><span>sort</span><span
            className="c5 c3">&nbsp;and organize, </span><span>customization</span><span className="c5 c3">&nbsp;to reduce the
            workload. To provide these and other </span><span>features</span><span className="c5 c3">&nbsp;AIDA accesses,
            archives and scans </span><span>Your</span><span className="c5 c3">&nbsp;</span><span>F</span><span
            className="c5 c3">iles</span><span>. You</span><span
            className="c5 c3">&nbsp;</span><span>give us</span><span
            className="c5 c3">&nbsp;your permission to </span><span>do</span><span
            className="c5 c3">&nbsp;th</span><span>e</span><span className="c5 c3">se operations and this permission extends to
        </span><span>our</span><span className="c4 c3">&nbsp;affiliates and trusted third parties we work with.</span>
        </p>
        <h2 className="c6" id="h.vxbd2b7pusaa"><span className="c3">2. </span><span>Your Responsibilities</span></h2>
        <ol className="c7 lst-kix_o7ezhiip8m3q-0 start" start="1">
            <li className="c2 li-bullet-0"><span>&nbsp;You are</span><span className="c5 c3">&nbsp;responsible for all activities
            </span><span>that occur under</span><span className="c5 c3">&nbsp;</span><span>your</span><span
                className="c5 c3">&nbsp;account</span><span className="c5 c3">, regardless of whether the activities were
                authorized by </span><span>you</span><span className="c5 c3">&nbsp;or undertaken by
            </span><span>you</span><span className="c5 c3">&nbsp;or</span><span>&nbsp;your</span><span
                className="c5 c3">&nbsp;employees or third part</span><span>ies</span><span
                className="c5 c3">&nbsp;(including
            </span><span>contractors</span><span className="c5 c3">, agents and the customer</span><span>&rsquo;s
                e</span><span className="c5 c3">nd </span><span>u</span><span
                className="c5 c3">sers</span><span>)</span><span
                className="c5 c3">, and </span><span>we</span><span
                className="c5 c3">&nbsp;and </span><span>our</span><span
                className="c5 c3">&nbsp;</span><span>affiliates</span><span className="c5 c3">&nbsp;</span><span>are not
                responsible</span><span className="c5 c3">&nbsp;for unauthorized access to </span><span>your</span><span
                className="c4 c3">&nbsp;account. </span></li>
        </ol>
        <p className="c11"><span className="c4 c3"></span></p>
        <ol className="c7 lst-kix_o7ezhiip8m3q-0" start="2">
            <li className="c2 li-bullet-0"><span>&nbsp;You</span><span
                className="c5 c3">&nbsp;</span><span>will</span><span
                className="c5 c3">&nbsp;ensure that </span><span>Your</span><span
                className="c5 c3">&nbsp;Documents</span><span>&nbsp;and </span><span
                className="c5 c3">Contents </span><span>and
                their use, or the use of offered services, by you or the final customers</span><span className="c5 c3">,
            </span><span>will not</span><span className="c5 c3">&nbsp;violate any </span><span>of the</span><span
                className="c5 c3">&nbsp;Polic</span><span>ies</span><span className="c5 c3">&nbsp;or any applicable law.
            </span><span>You are solely responsible</span><span
                className="c5 c3">&nbsp;for the </span><span>D</span><span
                className="c5 c3">ocuments and related </span><span>C</span><span className="c5 c3">ontents, management,
            </span><span>archival</span><span className="c4 c3">&nbsp;on AIDA and/or third parties and the subsequent use of
                both the Documents and its Contents.</span></li>
        </ol>
        <p className="c11"><span className="c4 c3"></span></p>
        <ol className="c7 lst-kix_o7ezhiip8m3q-0" start="3">
            <li className="c2 li-bullet-0">
                <span>&nbsp;</span><span>Help us keep Your Files and Contents protected</span><span>.
                Safeguard your password to the Services, and keep your account information current. Don&rsquo;t share
                your account credentials or give others access to your account.</span></li>
        </ol>
        <p className="c11 c12"><span className="c4 c3"></span></p>
        <ol className="c7 lst-kix_o7ezhiip8m3q-0" start="4">
            <li className="c2 li-bullet-0"><span>&nbsp;You may use AIDA Services only as permitted by applicable law, including
                export control laws and regulations. Finally, to use AIDA Services, you must be at least 13 (or older,
                depending on where you live).</span></li>
        </ol>
        <p className="c11 c12"><span className="c4 c3"></span></p>
        <ol className="c7 lst-kix_o7ezhiip8m3q-0" start="5">
            <li className="c2 li-bullet-0"><span>&nbsp;You will be deemed to have taken any action that you permit, assist or
                facilitate any person or entity to take related to this Agreement, AIDA or use of the Service Offerings.
                You are responsible for End Users&rsquo; use of AIDA Services. You will ensure that all End Users comply
                with your obligations under this Agreement and that the terms of your agreement with each End User are
                consistent with this Agreement. If you become aware of any violation of your obligations under this
                Agreement caused by an End User, you will immediately suspend access to AIDA services by such End User.
            </span><span>We do not provide any support or services to End Users unless we have a separate agreement with
                you or an End User obligating us to provide such support or services. </span><span
                className="c5 c3">&nbsp;</span></li>
        </ol>
        <h2 className="c6" id="h.oyle8z7majf7"><span className="c1">3. Software</span></h2>
        <ol className="c7 lst-kix_sg194g1stukh-0 start" start="1">
            <li className="c2 li-bullet-0"><span>&nbsp;</span><span className="c5 c3">Some of AIDA Services provide for the
                installation of client software (&quot;</span><span className="c5 c3 c10">Software</span><span
                className="c5 c3">&quot;). </span><span className="c4 c3">So long as you comply with these Terms, we give you a
                limited, non exclusive, non transferable, revocable license to use the Software, solely to access the
                Services.</span></li>
            <li className="c2 li-bullet-0"><span>&nbsp;To the extent that any component of the Software may be offered under an
                open source license, we&rsquo;ll make that license available to you and the provisions of that license
                may expressly override some of these Terms. </span><span>Unless the following restrictions are
                prohibited by law, you agree not to reverse engineer or decompile the Services, attempt to do so, or
                assist anyone in doing so.</span></li>
        </ol>
        <p className="c11"><span className="c4 c3"></span></p>
        <h2 className="c6" id="h.vo0s98lyyblw"><span className="c1">4. Beta Services</span></h2>
        <ol className="c7 lst-kix_kof3t6ybhv6y-0 start" start="1">
            <li className="c2 li-bullet-0"><span>We sometimes release products and features that we&rsquo;re still testing and
                evaluating (&ldquo;Beta Services&rdquo;). Beta Services are labeled &ldquo;alpha,&rdquo;
                &ldquo;beta,&rdquo; &ldquo;preview,&rdquo; &ldquo;early access,&rdquo; or &ldquo;evaluation&rdquo; (or
                with words or phrases with similar meanings) and may not be as reliable as other
                ours</span><span>&nbsp;s</span><span className="c5 c3">ervices</span><span>, therefore you must keep this in
                mind when you use these services. Beta Services are made available so that we can collect user feedback.
                By using our Beta Services, you agree that we may contact you to collect such feedback.</span></li>
            <li className="c2 li-bullet-0"><span>Beta Services are confidential until official launch. If you use any Beta
                Service, you agree not to disclose any information about those Services to anyone else without our
                permission.</span></li>
        </ol>
        <h2 className="c6" id="h.4cc7k2nygfif"><span className="c1">5. Our Services</span></h2>
        <ol className="c7 lst-kix_nuf0vl4g8dlm-0 start" start="1">
            <li className="c2 li-bullet-0"><span>You may access and use the Service Offerings in accordance with this
                Agreement.</span><span className="c5 c3">&nbsp;</span><span>You will comply with the terms of this Agreement
                and all laws, rules and regulations applicable to your use of the Service Offerings.</span></li>
            <li className="c2 li-bullet-0"><span>T</span><span className="c5 c3">o access the AIDA Services, the customer must have
                an AIDA account associated with a valid e-mail address and a valid payment method, required after the
                end of the trial period.</span></li>
            <li className="c2 li-bullet-0"><span className="c5 c3">AIDA Services are protected by copyright, trademarks and other
                laws of the Italian Republic, the European Union, the United States and other countries. These Terms do
                not grant the customer any right, title or interest </span><span>on</span><span
                className="c5 c3">&nbsp;AIDA
                Services, the contents of others in the Services, AIDA&#39;s brands and logos and other brand features.
            </span><span>We welcome user&rsquo;s feedback, but note that we may use comments or suggestions without any
                obligation to you.</span></li>
        </ol>
        <h2 className="c6" id="h.d9u54cirtqgu"><span className="c16 c3">6. Proprietary Rights</span></h2>
        <ol className="c7 lst-kix_2j0mo5lbrpx3-0 start" start="1">
            <li className="c2 li-bullet-0"><span>Except as provided in this Section 6, we obtain no rights under this Agreement
                from you (or your licensors) to Your Document and Content. You consent to our use of Document and
                Content to provide the Service Offerings to you and any End Users.</span></li>
            <li className="c2 li-bullet-0"><span className="c4 c3">You represent and warrant to us that: </span></li>
        </ol>
        <ol className="c7 lst-kix_2j0mo5lbrpx3-1 start" start="1">
            <li className="c0 li-bullet-0"><span className="c4 c3">You or your licensors own all right, title, and interest in and
                to Your Document and Content</span></li>
            <li className="c0 li-bullet-0"><span className="c4 c3">You have all rights in Your Document and Content necessary to
                grant the rights contemplated by this Agreement</span></li>
            <li className="c0 li-bullet-0"><span
                className="c4 c3">None of Your Document and Content or End Users&rsquo; use of
                Document and Content or the AIDA Service Offerings will violate the Acceptable Use Policy in section
                13</span></li>
        </ol>
        <ol className="c7 lst-kix_2j0mo5lbrpx3-0" start="3">
            <li className="c2 li-bullet-0"><span className="c4 c3">We and our licensors own all right and title, including those
                related to the technology in use and intellectual property, with regard to AIDA Services. </span></li>
        </ol>
        <p className="c12 c13"><span className="c4 c3">Subject to the terms of this Agreement, we grant you a limited, revocable,
            non-exclusive, non-sublicensable, non-transferable license to do the following:</span></p>
        <ol className="c7 lst-kix_lv55vg4zv10m-0 start" start="1">
            <li className="c0 li-bullet-0"><span className="c4 c3">Access and use AIDA Services solely in accordance with this
                Agreement</span></li>
            <li className="c0 li-bullet-0"><span className="c4 c3">Use AIDA Services solely in connection with your permitted use of
                the Services. Except as provided in this Section 6.3, you obtain no rights under this Agreement from us,
                our affiliates or our licensors to the Service Offerings, including any related intellectual property
                rights. Some AIDA or Third-Party&rsquo;s components may be provided to you under a separate license,
                such as for example an open source license. In the event of a conflict between this Agreement and any
                separate license, the separate license can expressly prevail on some of these Terms.</span></li>
        </ol>
        <ol className="c7 lst-kix_2j0mo5lbrpx3-0" start="4">
            <li className="c2 li-bullet-0"><span className="c4 c3">Neither you nor any End User will not be able to use any of AIDA
                Service in any manner or for any purpose other than those expressly permitted by this Agreement. Neither
                you nor any End User will, or will attempt:</span></li>
        </ol>
        <ol className="c7 lst-kix_2j0mo5lbrpx3-1 start" start="1">
            <li className="c0 li-bullet-0"><span className="c4 c3">Modify, distribute, alter, tamper with, repair, or otherwise
                create derivative works of any Content included in the Service Offerings (except to the extent Content
                included in the Service Offerings is provided to you under a separate license that expressly permits the
                creation of derivative works)</span></li>
            <li className="c0 li-bullet-0"><span>Reverse engineer, disassemble, or decompile the Service Offerings or apply any
                other process or procedure to derive the source code of any software included in the Service Offerings
                (except to the extent applicable law doesn&rsquo;t allow this restriction</span><span
                className="c5 c3">)</span></li>
            <li className="c0 li-bullet-0"><span className="c4 c3">Access or use the Service Offerings in a way intended to avoid
                incurring fees or exceeding usage limits or quotas</span></li>
            <li className="c0 li-bullet-0"><span>Resell or sublicense the Service Offerings. You may only use the AIDA Marks in
                accordance with the Trademark Use Guidelines.</span></li>
        </ol>
        <h2 className="c6" id="h.vp2iucquqhh3"><span className="c3 c16">7. Account and payment</span></h2>
        <ol className="c7 lst-kix_1opfzuw4ah2-0 start" start="1">
            <li className="c2 li-bullet-0"><span>We </span><span className="c5 c3">calculate </span><span>and bill fees and charges
                monthly or annually.</span><span className="c5 c3">&nbsp;</span><span>We may bill you more frequently for
                fees accrued if we suspect that your account is fraudulent or at risk of non-payment.</span><span
                className="c5 c3">&nbsp;</span><span>You will pay us the applicable fees and charges for use of the Service
                Offerings using one of the payment methods we support.</span><span className="c5 c3">&nbsp;</span><span>All
                amounts payable by you under this Agreement will be paid to us without setoff or counterclaim, and
                without any deduction or withholding. We may increase or add new fees and charges for any existing
                Services you are using by giving you at least 30 days&rsquo; prior notice. We may elect to charge you
                interest at the rate of 1.5% per month (or the highest rate permitted by law, if less) on all late
                payments</span><span className="c5 c3">.</span></li>
            <li className="c2 li-bullet-0"><span>Each party will be responsible, as required under applicable law, for
                identifying and paying all taxes and other governmental fees and charges (and any penalties, interest,
                and other additions thereto) that are imposed on that party upon or with respect to the transactions and
                payments under this Agreement. </span></li>
            <li className="c2 li-bullet-0"><span>You may</span><span className="c5 c3">&nbsp;cancel </span><span>your
                AIDA</span><span className="c5 c3">&nbsp;account at any time. Refunds are only issued </span><span
                className="c19">if required by law</span><span className="c5 c3">.</span></li>
            <li className="c2 li-bullet-0"><span className="c4 c3">We may change AIDA Services offering and AIDA fees in effect but
                will give you advance notice of these changes via a message to the email address associated with your
                account.</span></li>
        </ol>
        <h2 className="c6" id="h.lufz3xlwawp6"><span className="c16 c3">8. Termination</span></h2>
        <ol className="c7 lst-kix_vesqk89mn5nf-0 start" start="1">
            <li className="c2 li-bullet-0"><span>You can interrupt the use of AIDA services</span><span
                className="c5 c3">&nbsp;at
                any time for the accounts of the Professional &amp; Business plans.</span></li>
            <li className="c2 li-bullet-0"><span className="c5 c3">For the Enterprise plan </span><span>you</span><span
                className="c5 c3">&nbsp;</span><span>may terminate</span><span className="c5 c3">&nbsp;this Agreement for any
                reason by </span><span>providing</span><span className="c5 c3">&nbsp;us with a 90 days advance notice and
                closing </span><span>your</span><span className="c5 c3">&nbsp;account for all AIDA Services for which
            </span><span>the</span><span className="c5 c3">&nbsp;account closing mechanism is guaranteed.</span></li>
            <li className="c2 li-bullet-0"><span>I</span><span
                className="c5 c3">n turn </span><span>we may terminate</span><span
                className="c5 c3">&nbsp;this Agreement for any reason</span><span>&nbsp;by providing an advance notice of at
                least 90 days </span><span
                className="c5 c3">for the Enterprise plan and 30 days for the Professional &amp;
                                  Business plans.</span></li>
            <li className="c2 li-bullet-0"><span>&nbsp;We reserve the right to suspend or terminate your access to AIDA
                services</span><span className="c5 c3">&nbsp;with a 30 days advance notice</span><span>&nbsp;in the event
                that you</span><span className="c4 c3">:</span></li>
        </ol>
        <ol className="c7 lst-kix_vesqk89mn5nf-1 start" start="1">
            <li className="c0 li-bullet-0"><span>Breach of these Terms</span></li>
            <li className="c0 li-bullet-0"><span>Use the Services in a way that would cause a real risk of harm or loss to us or
                other users</span></li>
            <li className="c0 li-bullet-0"><span>Have </span><span className="c5 c3">an expired AIDA tr</span><span>ial
                a</span><span className="c5 c3">ccount trial and haven</span><span>&rsquo;t accessed AIDA</span><span
                className="c4 c3">&nbsp;Services for 3 consecutive months</span></li>
        </ol>
        <p className="c13 c12"><span>We&rsquo;ll provide you with reasonable advance notice via the email address associated to
            your account to remedy the activity that prompted us to contact you and give you the opportunity to export
            Your Document and Content from AIDA Services. If after such notice you fail to take the steps we ask of you,
            we&rsquo;ll terminate or suspend your access to AIDA Services.</span></p>
        <p className="c13 c12"><span>We won&rsquo;t provide notice before termination where</span><span
            className="c4 c3">:</span>
        </p>
        <ol className="c7 lst-kix_xv7wyrd6kn8j-0 start" start="1">
            <li className="c0 li-bullet-0"><span className="c4 c3">You&rsquo;re committing a serious violation of these Terms</span>
            </li>
            <li className="c0 li-bullet-0"><span className="c4 c3">Doing so would cause us legal liability or compromise our ability
                to provide the AIDA Services to other users</span></li>
            <li className="c0 li-bullet-0"><span>We&#39;re prohibited from doing so by law</span></li>
        </ol>
        <h2 className="c6" id="h.o77t94oxkbmr"><span className="c16 c3">9. Services &quot;AS IS&quot;</span></h2>
        <p className="c13"><span>We strive to provide great Services, but there are certain things that we can&#39;t
            guarantee.</span><span className="c3">&nbsp;</span><span>TO THE FULLEST EXTENT PERMITTED BY LAW</span><span
            className="c3">, </span><span>WE</span><span className="c3">&nbsp;AND OUR AFFILIATES, SUPPLIERS AND DISTRIBUTORS
        </span><span>MAKE NO WARRANTIES</span><span className="c3">, EITHER EXPRESS OR IMPLIED,
        </span><span>ABOUT</span><span className="c3">&nbsp;THE SERVICES. THE SERVICES ARE PROVIDED &quot;AS IS&quot;. WE DO
            NOT ALSO </span><span>DISCLAIM</span><span className="c3">&nbsp;ANY WARRANTY OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE AND OF NON-INFRINGEMENT. Some states do not allow the types of restrictions indicated in
            this section, so these restrictions may not apply.</span></p>
        <h2 className="c6" id="h.a95frtgsd3lb"><span className="c1">10. Limitation of liability</span></h2>
        <p className="c13"><span>WE AND OUR AFFILIATES AND LICENSORS WILL NOT BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT,
            INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES (INCLUDING DAMAGES FOR LOSS OF PROFITS, REVENUES,
            CUSTOMERS, OPPORTUNITIES, GOODWILL, USE, OR DATA), EVEN IF A PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF
            SUCH DAMAGES. FURTHER, NEITHER WE NOR ANY OF OUR AFFILIATES OR LICENSORS WILL BE RESPONSIBLE FOR ANY
            COMPENSATION, REIMBURSEMENT, OR DAMAGES ARISING IN CONNECTION WITH: (A) YOUR INABILITY TO USE THE SERVICES,
            INCLUDING AS A RESULT OF ANY (I) TERMINATION OR SUSPENSION OF THIS AGREEMENT OR YOUR USE OF OR ACCESS TO THE
            SERVICE OFFERINGS, (II) OUR DISCONTINUATION OF ANY OR ALL OF THE SERVICE OFFERINGS, OR, (III) WITHOUT
            LIMITING ANY OBLIGATIONS UNDER THE SERVICE LEVEL AGREEMENTS, ANY UNANTICIPATED OR UNSCHEDULED DOWNTIME OF
            ALL OR A PORTION OF THE SERVICES FOR ANY REASON; (B) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS OR
            SERVICES; (C) ANY INVESTMENTS, EXPENDITURES, OR COMMITMENTS BY YOU IN CONNECTION WITH THIS AGREEMENT OR YOUR
            USE OF OR ACCESS TO THE SERVICE OFFERINGS; OR (D) ANY UNAUTHORIZED ACCESS TO, ALTERATION OF, OR THE
            DELETION, DESTRUCTION, DAMAGE, LOSS OR FAILURE TO STORE ANY OF YOUR CONTENT OR OTHER DATA. IN ANY CASE,
            EXCEPT FOR PAYMENT OBLIGATIONS UNDER SECTION 7.1, OUR AND OUR AFFILIATES&rsquo; AND LICENSORS&rsquo;
                                 AGGREGATE LIABILITY UNDER THIS AGREEMENT WILL NOT EXCEED THE AMOUNT YOU ACTUALLY PAY US UNDER THIS AGREEMENT
            FOR THE SERVICE THAT GAVE RISE TO THE CLAIM IN THE PREVIOUS MONTHS BEFORE THE LIABILITY AROSE. THE
            LIMITATIONS IN THIS SECTION 10 APPLY ONLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.</span><span
            className="c3">&nbsp;</span></p>
        <h2 className="c6" id="h.b5oryj4bc2lb"><span className="c1">11. Dispute Resolution</span></h2>
        <ol className="c7 lst-kix_av2j7g5dgyh7-0 start" start="1">
            <li className="c2 li-bullet-0"><span className="c17 c3">First let&#39;s try to sort things out.</span><span
                className="c5 c3">&nbsp;</span><span>We want to address your concerns without needing a formal legal
                case</span><span className="c5 c3">. </span><span>Before filing a claim</span><span
                className="c5 c3">&nbsp;against </span><span>us</span><span
                className="c5 c3">, </span><span>you</span><span
                className="c5 c3">&nbsp;agree to try to resolve the dispute informally by
            </span><span>contacting</span><span className="c5 c3">&nbsp;us at the email address info@tclab.it.
            </span><span>We&rsquo;</span><span className="c5 c3">ll try to resolve the dispute informally by contacting
            </span><span>you</span><span className="c5 c3">&nbsp;via email. If the dispute is not resolved within 15 days of
            </span><span>submission</span><span className="c5 c3">, </span><span>you</span><span
                className="c5 c3">&nbsp;or
            </span><span>us may bring a formal proceeding</span><span className="c5 c3">.</span></li>
            <li className="c2 li-bullet-0"><span className="c3 c17">Judicial forum for disputes</span><span
                className="c5 c3">.
            </span><span>You</span><span className="c5 c3">&nbsp;and </span><span>us </span><span
                className="c5 c3">a</span><span>gree</span><span className="c5 c3">&nbsp;that any judicial proceeding to resolve
            </span><span>claims</span><span className="c5 c3">&nbsp;relating to these Terms or Services </span><span>will be
                brought</span><span className="c5 c3">&nbsp;exclusively </span><span>in</span><span
                className="c5 c3">&nbsp;the
                court of T</span><span>o</span><span className="c4 c3">rino, Italy.</span></li>
        </ol>
        <h2 className="c6" id="h.xvzks1b3ldaa"><span className="c16 c3">12. Changes</span></h2>
        <p className="c13"><span>We may revise these Terms from time to time to better reflect</span><span
            className="c4 c3">:</span></p>
        <ol className="c7 lst-kix_lhpp1oskcsz3-0 start" start="1">
            <li className="c2 li-bullet-0"><span className="c4 c3">changes to the law</span></li>
            <li className="c2 li-bullet-0"><span className="c4 c3">new regulatory requirements</span></li>
            <li className="c2 li-bullet-0"><span
                className="c4 c3">improvements or enhancements made to our Services</span></li>
        </ol>
        <p className="c11"><span className="c4 c3"></span></p>
        <p className="c11"><span className="c4 c3"></span></p>
        <h2 className="c6" id="h.r87c5p89am7g"><span className="c1">13. Acceptable Use Policy</span></h2>
        <p className="c11"><span className="c4 c3"></span></p>
        <p className="c13"><span className="c4 c3">You agree not to misuse the AIDA services (&quot;Services&quot;) or help anyone
            else to do so. For example, you must not even try to do any of the following in connection with the
            Services:</span></p>
        <ul className="c7 lst-kix_qrknd3pxmdav-0 start">
            <li className="c2 li-bullet-0"><span className="c4 c3">probe, scan, or test the vulnerability of any system or network,
                unless done in compliance with our Bug Bounty Program</span></li>
            <li className="c2 li-bullet-0"><span className="c4 c3">breach or otherwise circumvent any security or authentication
                measures</span></li>
            <li className="c2 li-bullet-0"><span className="c4 c3">access, tamper with, or use non-public areas or parts of the
                Services, or shared areas of the Services you haven&#39;t been invited to</span></li>
            <li className="c2 li-bullet-0"><span className="c4 c3">interfere with or disrupt any user, host, or network, for example
                by sending a virus, overloading, flooding, spamming, or mail-bombing any part of the Services</span>
            </li>
            <li className="c2 li-bullet-0"><span className="c4 c3">access, search, or create accounts for the Services by any means
                other than our publicly supported interfaces (for example, &quot;scraping&quot; or creating accounts in
                bulk)</span></li>
            <li className="c2 li-bullet-0"><span className="c4 c3">send unsolicited communications, promotions or advertisements, or
                spam</span></li>
            <li className="c2 li-bullet-0"><span className="c4 c3">send altered, deceptive or false source-identifying information,
                including &quot;spoofing&quot; or &quot;phishing&quot;</span></li>
            <li className="c2 li-bullet-0"><span className="c4 c3">promote or advertise products or services other than your own
                without appropriate authorization</span></li>
            <li className="c2 li-bullet-0"><span className="c4 c3">abuse referrals or promotions to get access other features than
                the ones deserved or to sell any feature received from referrals or promotions</span></li>
            <li className="c2 li-bullet-0"><span
                className="c4 c3">circumvent either storage space or feature limits</span></li>
            <li className="c2 li-bullet-0"><span className="c4 c3">sell the Services unless specifically authorized to do so</span>
            </li>
            <li className="c2 li-bullet-0"><span className="c4 c3">publish or share materials that are unlawfully pornographic or
                indecent, or that contain extreme acts of violence or terrorist activity, including terror
                propaganda</span></li>
            <li className="c2 li-bullet-0"><span className="c4 c3">advocate bigotry or hatred against any person or group of people
                based on their race, religion, ethnicity, sex, gender identity, sexual orientation, disability, or
                impairment</span></li>
            <li className="c2 li-bullet-0"><span className="c4 c3">harass or abuse our personnel or representatives or agents
                performing services on behalf of us</span></li>
            <li className="c2 li-bullet-0"><span className="c4 c3">violate the law in any way, including storing, publishing or
                sharing material that&#39;s fraudulent, defamatory, or misleading</span></li>
            <li className="c2 li-bullet-0"><span
                className="c4 c3">violate the privacy or infringe the rights of others</span></li>
        </ul>

        <h2>14. Release for the Use of the Logo</h2>
        <p>Hereby, non-exclusive rights are granted to Technology & Cognition Lab S.r.l. to use, reproduce, display, and
           distribute the client company's logo for promotional, advertising, and/or marketing purposes, solely for
           indicating and representing the collaboration between the company and AIDA.</p>
        <p>This authorization is granted in compliance with the provisions of the Industrial Property Code (Legislative
           Decree 30/2005) and subsequent amendments, and Regulation (EU) 2017/1001 on the European Union trademark.</p>
        <p>Technology & Cognition Lab S.r.l. commits to ensuring that the use of the logo is carried out in respect of
           the integrity, image, and reputation of the client company, ensuring that the use of the logo does not
           prejudice, directly or indirectly, the rights of the holder or the related company.</p>
        <p>Any other use of the logo, different from what is specified in this release, is expressly forbidden, unless a
           new, explicit written authorization is obtained from the rights holder of the logo.</p>
    </>;
};

export default TermsOfServiceEn;
